import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from '/src/components/PreviewCompatibleImage'
import { kebabCase } from "lodash";

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const postLinks = posts.map((post) => (
      <div className="">
          <Link to={post.node.fields.slug}>
            <article
            className={`blog-list-item flex items-center${
              post.node.frontmatter.featuredpost ? '' : ''
              }`}
          >
              {post.node.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.node.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.node.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="ml-6">
                    <span className="label">
                      {post.node.frontmatter.title}
                    </span>
                    <span className="block text-sm text-gray-400 pb-2 mb-0" style={{textDecoration:`none`}}>
                      {post.node.frontmatter.date}
                    </span>
                    {post.node.frontmatter.tags ? (
                      post.node.frontmatter.tags.map((tag) => (                                          
                      <Link to={`/tags/${kebabCase(tag)}/`}>
                          <span className="tag-outlined text-sm">{tag}</span>
                      </Link>
                      ))
                    ) : null}
                  </div>
            </article>
          </Link>      
      </div> 
    ));
    const tag = this.props.pageContext.tag;
    const title = this.props.data.site.siteMetadata.title;
    const totalCount = this.props.data.allMarkdownRemark.totalCount;
    const tagHeader = `${tag}(${totalCount}件)`;

    return (
      <Layout>
        <section className="mx-4">
          <Helmet title={`${tag} | ${title}`} />
          <div className="container-1">
            <div className="my-12">
              <h1 className="mb-6 text-3xl font-bold text-text-primary">
                {tagHeader}
                </h1>
                {postLinks}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "YYYY-MM-DD")
            featuredpost
            tags
            featuredimage {
              childImageSharp {
                gatsbyImageData(
                  width: 106
                  height: 106
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;
